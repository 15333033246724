import React from "react";

const Header = () => {
  return (
    <header className="fixed top-0 w-full z-50">
      <nav className=" border-gray-200 shadow-md py-2.5 bg-[#181a1e]">
        <div className="flex flex-wrap items-center justify-between max-w-screen-xl px-4 mx-auto">
          <a className="flex items-center" href="/">
            <img
              src="/logo.png"
              className="h-12 mr-3"
              alt="ridebnb"
            />
            <span className="self-center text-2xl font-bold whitespace-nowrap text-white"></span>
          </a>
          <div className="flex items-center lg:order-2">
            <button className="text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0 dark:bg-red-500 dark:hover:bg-red-600 focus:outline-none dark:focus:ring-red-400">
              Logout
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
