import React from "react";

const Banner = () => {
  return (
    <section className=" bg-[#14151A] transition-colors duration-1000">
      <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
        <div className="mr-auto place-self-center lg:col-span-7">
          <p className="max-w-2xl mb-4  font-bold text-xl text-purple-500">
            EMPOWERING EACH THROUGH GLOBAL REACH
          </p>
          <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl text-[#FFE900]">
            Building Dreams <br />
            Together.
          </h1>
          <p className="max-w-2xl mb-6  lg:mb-8 md:text-lg lg:text-xl text-[#c4c5cb]">
            <b>RIDE BNB</b> is a pioneering Web 3.0 platform powered by
            blockchain and smart contracts, designed to empower users on their
            journey towards economic independence and progressive lifestyles. By
            utilizing cutting-edge blockchain technology, BNB Ride ensures
            secure, transparent pathways for community members to achieve their
            personal and professional goals. Our platform fosters a
            collaborative community where success is shared, amplified, and
            accessible to all, driving growth through innovation and support.
          </p>
          <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
            <button className=" bg-pink-500 focus:ring-4 font-medium rounded-lg text-sm px-6 lg:px-8 py-2 lg:py-2.5 sm:mr-2 lg:mr-0 text-white transition-all duration-1000 ease-in-out  hover:text-black hover:bg-white focus:outline-none focus:ring-gray-300">
              Start Now
            </button>
          </div>
        </div>
        <div className="lg:mt-0 lg:col-span-5 lg:flex">
          <img src="/hero.png" alt="hero image" className="h-[400px]" />
        </div>
      </div>
    </section>
  );
};

export default Banner;
