export const MY_CONTRACT_ADDRESS = "0xB469E7072038E18c5B81B8EcCB01d008F9f00355";

export const MY_CONTRACT_ABI = [
    {
      inputs: [
        { internalType: "address", name: "_feeReceiver", type: "address" },
        { internalType: "address", name: "_royalty", type: "address" },
        { internalType: "address", name: "_owner", type: "address" },
      ],
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      name: "activity",
      outputs: [
        { internalType: "uint256", name: "id", type: "uint256" },
        { internalType: "uint256", name: "level", type: "uint256" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "", type: "uint256" },
        { internalType: "uint256", name: "", type: "uint256" },
      ],
      name: "directTeam",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "distributeRoyalty",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "_user", type: "uint256" }],
      name: "getDirectTeamUsers",
      outputs: [
        {
          components: [
            { internalType: "address", name: "account", type: "address" },
            { internalType: "uint256", name: "id", type: "uint256" },
            { internalType: "uint256", name: "referrer", type: "uint256" },
            { internalType: "uint256", name: "upline", type: "uint256" },
            { internalType: "uint256", name: "start", type: "uint256" },
            { internalType: "uint256", name: "level", type: "uint256" },
            { internalType: "uint256", name: "directTeam", type: "uint256" },
            {
              internalType: "uint256",
              name: "totalMatrixTeam",
              type: "uint256",
            },
            { internalType: "uint256", name: "totalIncome", type: "uint256" },
            { internalType: "uint256", name: "totalDeposit", type: "uint256" },
            { internalType: "uint256", name: "royaltyIncome", type: "uint256" },
            {
              internalType: "uint256",
              name: "referralIncome",
              type: "uint256",
            },
            { internalType: "uint256", name: "levelIncome", type: "uint256" },
            {
              internalType: "uint256[12]",
              name: "income",
              type: "uint256[12]",
            },
          ],
          internalType: "struct FinBNB.User[]",
          name: "",
          type: "tuple[]",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "_user", type: "uint256" }],
      name: "getIncome",
      outputs: [
        {
          components: [
            { internalType: "uint256", name: "id", type: "uint256" },
            { internalType: "uint256", name: "layer", type: "uint256" },
            { internalType: "uint256", name: "amount", type: "uint256" },
            { internalType: "uint256", name: "time", type: "uint256" },
          ],
          internalType: "struct FinBNB.Income[]",
          name: "",
          type: "tuple[]",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "_id", type: "uint256" }],
      name: "getLevelIncome",
      outputs: [{ internalType: "uint256[12]", name: "", type: "uint256[12]" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getLevels",
      outputs: [
        { internalType: "uint256[12]", name: "", type: "uint256[12]" },
        { internalType: "uint256[12]", name: "", type: "uint256[12]" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "_user", type: "uint256" }],
      name: "getMatrixDirect",
      outputs: [
        { internalType: "uint256[2]", name: "_directs", type: "uint256[2]" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "_user", type: "uint256" },
        { internalType: "uint256", name: "_layer", type: "uint256" },
      ],
      name: "getMatrixUsers",
      outputs: [
        {
          components: [
            { internalType: "address", name: "account", type: "address" },
            { internalType: "uint256", name: "id", type: "uint256" },
            { internalType: "uint256", name: "referrer", type: "uint256" },
            { internalType: "uint256", name: "upline", type: "uint256" },
            { internalType: "uint256", name: "start", type: "uint256" },
            { internalType: "uint256", name: "level", type: "uint256" },
            { internalType: "uint256", name: "directTeam", type: "uint256" },
            {
              internalType: "uint256",
              name: "totalMatrixTeam",
              type: "uint256",
            },
            { internalType: "uint256", name: "totalIncome", type: "uint256" },
            { internalType: "uint256", name: "totalDeposit", type: "uint256" },
            { internalType: "uint256", name: "royaltyIncome", type: "uint256" },
            {
              internalType: "uint256",
              name: "referralIncome",
              type: "uint256",
            },
            { internalType: "uint256", name: "levelIncome", type: "uint256" },
            {
              internalType: "uint256[12]",
              name: "income",
              type: "uint256[12]",
            },
          ],
          internalType: "struct FinBNB.User[]",
          name: "",
          type: "tuple[]",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "_num", type: "uint256" }],
      name: "getRecentActivities",
      outputs: [
        {
          components: [
            { internalType: "uint256", name: "id", type: "uint256" },
            { internalType: "uint256", name: "level", type: "uint256" },
          ],
          internalType: "struct FinBNB.Activity[]",
          name: "",
          type: "tuple[]",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getRoyaltyTime",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "_royalty", type: "uint256" }],
      name: "getRoyaltyUsers",
      outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      name: "globalUsers",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "", type: "address" }],
      name: "id",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "", type: "uint256" },
        { internalType: "uint256", name: "", type: "uint256" },
      ],
      name: "incomeInfo",
      outputs: [
        { internalType: "uint256", name: "id", type: "uint256" },
        { internalType: "uint256", name: "layer", type: "uint256" },
        { internalType: "uint256", name: "amount", type: "uint256" },
        { internalType: "uint256", name: "time", type: "uint256" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      name: "matrixDirect",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "_ref", type: "uint256" },
        { internalType: "address", name: "_newAcc", type: "address" },
      ],
      name: "register",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      name: "royalty",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "royaltyLastDist",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "", type: "uint256" },
        { internalType: "uint256", name: "", type: "uint256" },
      ],
      name: "royaltyUsers",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "startTime",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "", type: "uint256" },
        { internalType: "uint256", name: "", type: "uint256" },
        { internalType: "uint256", name: "", type: "uint256" },
      ],
      name: "teams",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "totalUsers",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "transferOwnershipToZeroAddress",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "_id", type: "uint256" },
        { internalType: "uint256", name: "_lvls", type: "uint256" },
      ],
      name: "upgrade",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      name: "userInfo",
      outputs: [
        { internalType: "address", name: "account", type: "address" },
        { internalType: "uint256", name: "id", type: "uint256" },
        { internalType: "uint256", name: "referrer", type: "uint256" },
        { internalType: "uint256", name: "upline", type: "uint256" },
        { internalType: "uint256", name: "start", type: "uint256" },
        { internalType: "uint256", name: "level", type: "uint256" },
        { internalType: "uint256", name: "directTeam", type: "uint256" },
        { internalType: "uint256", name: "totalMatrixTeam", type: "uint256" },
        { internalType: "uint256", name: "totalIncome", type: "uint256" },
        { internalType: "uint256", name: "totalDeposit", type: "uint256" },
        { internalType: "uint256", name: "royaltyIncome", type: "uint256" },
        { internalType: "uint256", name: "referralIncome", type: "uint256" },
        { internalType: "uint256", name: "levelIncome", type: "uint256" },
      ],
      stateMutability: "view",
      type: "function",
    },
    { stateMutability: "payable", type: "receive" },
  ];