import React, { createContext, useContext, useState, useEffect } from "react";
import { ethers } from "ethers";
import { MY_CONTRACT_ABI, MY_CONTRACT_ADDRESS } from "../config";

const EthereumContext = createContext();

export const useEthereum = () => useContext(EthereumContext);

export const EthereumProvider = ({ children }) => {
  const [account, setAccount] = useState(null);
  const [balance, setBalance] = useState("0.00000");
  const [userId, setUserId] = useState(0);
  const [userInfo, setUserInfo] = useState();
  const [levelIncome, setLevelIncome] = useState();
  const [referralData, setReferralData] = useState();
  const [contract, setContract] = useState(null); // Store the contract instance

  useEffect(() => {
    const loadProvider = async () => {
      if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const accounts = await provider.listAccounts();
        if (accounts.length > 0) {
          const userAddr = accounts[0];
          if (userAddr !== account) {
          setAccount(userAddr);

          const balanceInWei = await provider.getBalance(userAddr);
          const formattedBalance = ethers.utils.formatEther(balanceInWei);
          setBalance(parseFloat(formattedBalance).toFixed(2));
          }
          const contractInstance = new ethers.Contract(
            MY_CONTRACT_ADDRESS,
            MY_CONTRACT_ABI,
            provider
          );
          setContract(contractInstance); // Set contract instance for later use

          const userId = await contractInstance.id(userAddr);
          const userInfo = await contractInstance.userInfo(userId);
          const levelIncome = await contractInstance.getLevelIncome(userId);
          const referralData = await contractInstance.getDirectTeamUsers(
            userId
          );

          setUserId(userId.toString());
          setUserInfo(userInfo);
          setLevelIncome(levelIncome);
          setReferralData(referralData);
        } else {
          console.error("No Ethereum accounts found.");
        }
      } else {
        console.error("Ethereum object not found.");
      }
    };

    loadProvider();
  }, [account]);

  const getMatrixUsers = async (userId, level) => {
    if (contract) {
      try {
        return await contract.getMatrixUsers(userId, level);
      } catch (error) {
        console.error("Error fetching matrix users:", error);
      }
    }
    return [];
  };

  return (
    <EthereumContext.Provider
      value={{
        account,
        balance,
        userId,
        userInfo,
        levelIncome,
        referralData,
        getMatrixUsers, // Expose the function
      }}
    >
      {children}
    </EthereumContext.Provider>
  );
};
